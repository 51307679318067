.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.section__jwPr9 {
  display: grid;
  position: relative;
  align-content: space-between;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background: #0472ff29;
  height: 100vh;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__jwPr9 > * {
  grid-column: 4;
}
.section__quYnx {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__quYnx > * {
  grid-column: 4;
}
.img {
  object-fit: contain;
  max-width: 100%;
  width: 150px;
  margin-bottom: -23px;
  margin-top: 19px;
}
.img > picture > img {
  object-fit: contain;
}
.section__umQ6P {
  display: grid;
  position: relative;
  align-content: space-between;
  justify-items: center;
  width: 1000px;
  height: 500px;
  background: #f9f9f9;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  grid-row-gap: 20px;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  border-radius: 50px;
  padding: 50px;
}
.section__umQ6P > * {
  grid-column: 4;
}
.section__hnMXg {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-row-gap: 15px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__hnMXg > * {
  grid-column: 4;
}
.h1__x8Sei {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 50px;
  font-family: "Public Sans", sans-serif;
  outline-color: #000000;
  outline-width: thin;
  color: #2f2f2f;
  font-weight: 700;
  min-width: 0;
}
.h1__bHtWi {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: "Public Sans", sans-serif;
  outline-color: #000000;
  outline-width: thin;
  color: #0083c5;
  font-weight: 400;
  min-width: 0;
}
.section__wMal2 {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  justify-self: flex-start;
  min-width: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__wMal2 > * {
  grid-column: 4;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.section__zrKx0 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__zrKx0 > * {
  grid-column: 4;
}
.section__bfu0 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 30px;
}
.section__bfu0 > * {
  grid-column: 4;
}
.h1__t8Sny {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: "Public Sans", sans-serif;
  outline-color: #000000;
  outline-width: thin;
  color: #00b27c;
  font-weight: 400;
  min-width: 0;
}
